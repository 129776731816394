<template>
	<div class="machine-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$route.name === 'machine-edit' ? $t("titles.editMachine") : $t("titles.createMachine")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0" v-if="mayEdit">
				<small-button :text="$route.name === 'machine-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="hasFetchedData === false" />
		<template v-else>
			<form-container>
				<div class="part-label col-12">{{$t("titles.machineDetails")}}</div>
				<field-input :label='$t("labels.name")' v-model='name' :error="nameError" />
				<field-input :label='$t("labels.serial")' v-model='serial' :error="serialError" />
				<entity-select :label='$t("labels.district")' v-model='district' entity="District" search-property="name" display-property="name" value-property="@id" :error="districtError"  />
				<field-select :label='$t("labels.status")' :options='[{value: "1", text: $t("terms.active")},{value:"0", text: $t("terms.inactive")}]' v-model='status' :error="statusError"  />
				<entity-select v-if="hasGlobalSuperAdmin" :label='$t("labels.company")' v-model='company' entity="Company" search-property="name" display-property="name" value-property="@id" :error="companyError" :pay-load-params="{type: 1}"  />
				<entity-select v-if="hasGlobalSuperAdmin" :label='$tc("terms.trailer")' v-model='trailer' entity="Trailer" search-property="serialNumber" display-property="serialNumber" value-property="@id" :error="trailerError" />
			</form-container>
		</template>
	</div>
</template>

<script>
	
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState,mapGetters} from "vuex";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import FormContainer from '@/components/container/FormContainer.vue';
	import FieldInput from '@/components/inputs/FieldInput.vue';
	import FieldSelect from '@/components/inputs/FieldSelect.vue';
	import SmallButton from '@/components/buttons/SmallButton.vue';
	import permissions from "@/permissions";
	import isGranted from "@/mixin/isGranted";
	import DataConversionMethods from "@/mixin/dataConversionMethods";
	
	export default {
		name: "AddEditmachine",
		components: {Spinner, StandardButton, RegularInput, FieldInput, FormContainer, EntitySelect, FieldSelect, SmallButton},
		mixins: [isGranted],
		data(){
			return {
				hasFetchedData: false,
				responseError: '',
				loader: false,
				name: '',
				nameError: '',
				serial: '',
				serialError: '',
				district: '',
				districtError: '',
				company: '',
				companyError: '',
				status: 1,
				statusError: '',
				trailer: '',
				trailerError: '',
			}
		},
		computed: {
			...mapState('concrete', {
				machines: 'machines'
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.machines[this.$route.params.id] !== 'undefined') ? this.machines[this.$route.params.id] : false ;
			},
			hasError(){
				return this.nameError !== '' || this.serialError !== '' || this.districtError !== '' || this.companyError !== '' || this.statusError !== '' || this.trailerError !== '';
			},
			mayEdit(){
				if(this.hasGlobalSuperAdmin || this.isGranted(permissions.MACHINE.UPDATE) )
					return true;

				if(this.viewingEntity === false)
					return false;

				if(typeof this.routeEntity.company !== 'undefined' && this.isGranted(permissions.MACHINE.UPDATE,this.routeEntity.company) )
					return true;

				return (this.viewingEntity !== false) ? this.isGranted(permissions.MACHINE.UPDATE,this.viewingEntity) : false;
			}
		},
		methods: {
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'Machine'
					},
					method: (this.$route.name === 'machine-edit') ? 'put' : 'post',
					payload: {
						name: this.name,
						serial: this.serial,
						district: this.district !== '' ? this.district : null,
						status: parseInt(this.status)
					}
				};

				if(this.hasGlobalSuperAdmin)
				{
					payload.payload.company = this.company !== '' ? this.company : null
					payload.payload.trailer = this.trailer !== '' ? this.trailer : null
				}


				if(this.$route.name === 'machine-edit')
					payload.entity.id = this.$route.params.id;
				return payload;
			},
			attemptSubmit(){
				if(this.loader) return;
				this.validateInputs();
				if(!this.hasError)
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
							if(this.$route.name === 'machine-add')
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.machineWasCreatedTitle"),
									text: this.$t("alerts.machineWasCreatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.push({ name: 'entity-list', params: {entityType: 'machines',lang: this.$i18n.locale} })
									}
								});
							}
							else
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.machineWasUpdatedTitle"),
									text: this.$t("alerts.machineWasUpdatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((response) => {
									if (response.isConfirmed) {
										this.$router.push({ name: 'machine-view', params: {id: result.id,lang: this.$i18n.locale} })
									}
								});
							}
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined')
						{
							// @todo Handle specific errors
							if(typeof error.data['violations'] !== 'undefined')
							{
								error.data['violations'].forEach((error) => {
									if(typeof this[error.propertyPath+'Error'] !== 'undefined')
									{
										this[error.propertyPath+'Error'] = this.$t('symfonyErrors.'+error.message.replace(/\./g, ""));
									}
								});
								this.$swal.fire({
									icon: 'error',
									title: this.$t("terms.error"),
									text: this.$t("errors.errorsInFields"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								});
							}
							else
							{
								this.fireGenericError();
							}
						}
						else
						{
							this.fireGenericError();
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){
				this.nameError = '';
				this.serialError = '';
				this.districtError = '';
				this.statusError = '';
				if(this.name === '')
					this.nameError = this.$t("validation.pleaseProvideMachineName");
				if(this.serial === '')
					this.serialError = this.$t("validation.pleaseProvideSerial");

				if(this.status === '')
					this.statusError = this.$t("validation.pleaseSelectStatus");
			},
			fetchRouteEntity(){
				if(this.routeEntity === false)
				{
					this.hasFetchedData = false;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Machine'}})
					.then(result => {
						this.updateLocalValues();
					})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.hasFetchedData = true;
					},() => {
						this.hasFetchedData = true;
					})
				}
				else
				{
					this.updateLocalValues();
					this.hasFetchedData = true;
				}
			},
			updateLocalValues(){
				this.name = this.routeEntity.name;
				this.serial = this.routeEntity.serial;
				this.district = (typeof this.routeEntity.district === 'object' && this.routeEntity.district !== null) ? this.routeEntity.district['@id'] : this.routeEntity.district;
				this.status = this.routeEntity.status;
				this.company = (typeof this.routeEntity.company === 'object' && this.routeEntity.company !== null) ? this.routeEntity.company['@id'] : this.routeEntity.company;
				this.trailer = (typeof this.routeEntity.trailer === 'object' && this.routeEntity.trailer !== null) ? this.routeEntity.trailer['@id'] : this.routeEntity.trailer;
			}
		},
		mounted()
		{
			if(typeof this.$route.params.id !== 'undefined')
			{
				this.fetchRouteEntity();
			}
			else
			{
				this.hasFetchedData = true;
			}
		},
		watch:{
			$route(){
				if(typeof this.$route.params.id !== 'undefined')
				{
					this.fetchRouteEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
